import React, { useState } from "react"
import { Link } from "gatsby"
import { Navbar, Nav } from "react-bootstrap"

const Header = () => {
  const [burger, setBurger] = useState(false)
  return (
    <header className="site-header">
      <div className="container p-0">
        <Navbar expand="lg" variant="light">
          <div className="navbar-brand">
            <Link
              className="d-inline-block brand h1 mb-0 text-white text-uppercase"
              to="/"
            >
              <img
                className="img-fluid"
                src="/images/logo-side.png"
                alt="Window Magic logo"
              />
            </Link>
          </div>
          <Navbar.Toggle
            className={
              burger
                ? "is-active hamburger hamburger--spring"
                : "hamburger hamburger--spring"
            }
            aria-controls="navbarSupportedContent"
            onClick={() => setBurger(!burger)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav id="menu-header" className="nav-primary ml-auto">
              <Link className="nav-link" to="/">
                Home
              </Link>
              <Link className="nav-link" to="/automotive">
                Automotive
              </Link>
              <Link className="nav-link" to="/commercial">
                Commercial
              </Link>
              <Link className="nav-link" to="/residential">
                Residential
              </Link>

              <Link className="nav-link" to="/contact">
                Contact
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  )
}

export default Header
