import React from "react"
import Image from "gatsby-image"

const PageHeader = props => (
  <div className="page-header text-center">
    <Image className="feature-banner__bg" fluid={props.fluid} alt={props.alt} />
    <div className="container">
      <h1>{props.title}</h1>
    </div>
  </div>
)

export default PageHeader
