import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer className="site-footer page-section">
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-lg-3 mb-gutter">
          <h5>About Us</h5>
          <p>
            For decades Window Magic has been the leading name in safe and
            affordable window tinting within the Wellington region.
          </p>
        </div>
        <div className="col-sm-6 col-lg-3 mb-gutter">
          <h5>Contact Info</h5>
          <ul className="fa-ul">
            <li>
              <span className="fa-li">
                <i className="fas fa-phone"></i>
              </span>
              <a href="tel:+64274449914">027 444 9914</a>
            </li>
            <li>
              <span className="fa-li">
                <i className="fas fa-envelope"></i>
              </span>
              <a href="mailto:greg@windowmagic.co.nz">greg@windowmagic.co.nz</a>
            </li>
            <li>
              <span className="fa-li">
                <i className="fas fa-map-marker-alt"></i>
              </span>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://goo.gl/maps/xPTS3DyP6mquMNP49"
              >
                Unit 1, 4 Freshfields Place Plimmerton, Porirua 5026
              </a>
            </li>
            <li>
              <span className="fa-li">
                <i className="fab fa-facebook-square"></i>
              </span>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/WindowMagicNZ/"
              >
                Facebook
              </a>
            </li>
          </ul>
        </div>
        <div className="col-sm-6 col-lg-3 mb-gutter">
          <h5>Quick Links</h5>
          <ul id="menu-services" className="nav flex-column">
            <li className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-68">
              <Link to="/automotive" className="nav-link">
                Automotive
              </Link>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-67">
              <Link to="/commercial" className="nav-link">
                Commercial
              </Link>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-66">
              <Link to="/residential" className="nav-link">
                Residential
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-sm-6 col-lg-3">
          <h5>Opening Hours</h5>
          <ul className="nav flex-column">
            <li className="nav-item">
              <span className="nav-link">Weekdays: 09:00 – 17:00</span>
            </li>
            <li className="nav-item">
              <span className="nav-link">Saturday: 09:00 – 15:00</span>
            </li>
            <li className="nav-item">
              <span className="nav-link">Sunday: CLOSED</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
