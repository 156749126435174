import React from "react"

export default function WhyChooseUs() {
  return (
    <section className="page-section why-us">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <h2 className="section-heading">Why Choose Us</h2>
            <ul className="fa-ul pl-4">
              <li className="d-flex align-items-center">
                <span className="fa-li fa-2x text-warning">
                  <i className="fas fa-check-circle"></i>
                </span>
                Over 30 years’ experience
              </li>
              <li className="d-flex align-items-center">
                <span className="fa-li fa-2x text-warning">
                  <i className="fas fa-check-circle"></i>
                </span>
                Pick up &amp; drop off service
              </li>
              <li className="d-flex align-items-center">
                <span className="fa-li fa-2x text-warning">
                  <i className="fas fa-check-circle"></i>
                </span>
                Quality approved product
              </li>
              <li className="d-flex align-items-center">
                <span className="fa-li fa-2x text-warning">
                  <i className="fas fa-check-circle"></i>
                </span>
                Full warranty on all our products sold
              </li>
              <li className="d-flex align-items-center">
                <span className="fa-li fa-2x text-warning">
                  <i className="fas fa-check-circle"></i>
                </span>
                Seasoned workmanship
              </li>
            </ul>
          </div>
          <div className="col-lg-7 ml-auto">
            <h2 className="section-heading">Get your free quote</h2>

            <form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="contact"
              action="/thank-you"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />

              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <span className="wpcf7-form-control-wrap first-name">
                      <input
                        type="text"
                        name="first-name"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                        id="firstName"
                        required
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <span className="wpcf7-form-control-wrap last-name">
                      <input
                        type="text"
                        name="last-name"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                        id="lastName"
                        required
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <span className="wpcf7-form-control-wrap your-email">
                      <input
                        type="email"
                        name="your-email"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control"
                        id="email"
                        required
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <span className="wpcf7-form-control-wrap your-phone">
                      <input
                        type="tel"
                        name="your-phone"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel form-control"
                        id="phone"
                        required
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="city">City/Town</label>
                    <span className="wpcf7-form-control-wrap your-city">
                      <input
                        type="text"
                        name="your-city"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                        id="city"
                        required
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="subject">Subject</label>
                    <span className="wpcf7-form-control-wrap your-subject">
                      <input
                        type="text"
                        name="your-subject"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                        id="subject"
                        required
                      />
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <span className="wpcf7-form-control-wrap your-message">
                      <textarea
                        name="your-message"
                        cols={40}
                        rows={6}
                        className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required form-control"
                        id="message"
                        required
                      />
                    </span>
                  </div>
                  <input
                    type="submit"
                    value="Send Message"
                    className="wpcf7-form-control wpcf7-submit btn btn-danger"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
